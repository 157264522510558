import React from 'react';
// import HeaderMenu from './headermenu.js';
import './soon.css';
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// function PageHeader() {
//   return(
//     <header className="App-header">
//       <h1>Coming Soon</h1>
//       <h3>A Massively Multiplayer Arena Brawler</h3>
//       {/*<p id="playerCounter" className="playerCount">9001 Wastelanders currently opposing The Corporate Government</p>*/}
//     </header>);
// }
function Footer() {
  return(
    <div className="pageFooter">
    ©{new Date().getFullYear()} Nethash AB
    </div>
  )
}

function ComingSoon() {
  return(
    <div className="Coming-Soon-Container">
      <img className="Logo" src="./LOGO_512x512.png"/>
      <h1 className="Title">Coming Soon™</h1>
      <h3 className="Subtitle">
        A Massively Multiplayer Online Battle Arena Racer
        <br/>
        <a className="noStyling" href="https://discord.gg/hCyZj8E">
          Join our Discord!
        </a>
      </h3>
    </div>
  )
}

export class App extends React.Component {
  state = {
    images:[]
    // imgWallHTML:null
  }

  componentDidMount() {
    let li = [];
    for (let i=1; i < 33; i++) {
      let uri = `/new/img_ (${i}).png`;
      li.push(uri);
    }
    this.setState({images: li});
    // document.getElementsByClassName("imgWallItem")
    // fetch("https://steamcommunity.com/id/Hoffa/images").then((res)=>{
    //   this.setState({imgWallHTML:res});
    // }).catch((e)=>{
    //   console.log("Error!", e);
    // })
  }
  render(){
    return (
      <div className="App">

        <ComingSoon/>
        {/*<PageHeader/>
        <HeaderMenu/>
      */}

        <div className="Wall-Storage">
          {
            this.state.images.map((url, i)=>{
              return (
                <div className="img-wrapper">
                  <a href={url} key={`a-${i}`}>
                    <img src={url} alt="Pretty gfx" className="Wall-Image" key={`img-${i}`}/>
                  </a>
                </div>
              );

            })
          }
        </div>
      <Footer/>
      </div>
    )
  }
}
export default App;
